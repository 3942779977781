import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
// import GuideView from '../views/GuideView.vue';
import GuideView from '../views/GuideNewView.vue';
import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
const { oeReport } = Haa.util().useOeReport();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Guide',
        component: GuideView,
        meta: {
            spaceKeys: ['Guide_native', 'Guide_banner'],
        },
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/HomeView.vue'),
        meta: {
            spaceKeys: ['Home_inter', 'Home_banner', 'Home_native', 'Home_native_center1'],
        },
    },
    {
        path: '/detail',
        name: 'Detail',
        component: () => import('../views/DetailView.vue'),
        meta: {
            spaceKeys: ['Detail_inter', 'Detail_banner', 'Detail_native'],
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const { useParams } = require('@/store/useParams');
    const { params } = useParams();
    if (to.path == '/' && params.skip == 1) {
        oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'skip=1直接上报');
        next('/home');
    } else {
        next();
    }
})

export default router;
