import { Haa } from "@haa/fwk-h5/lib/index";
import bus from "@haa/fwk-h5/lib/bus";

import { ref } from "vue";

/** 封装获取策略信息hooks */
const { useStrategy } = Haa.util();
type IStrategy = ReturnType<typeof useStrategy>["strategy"];

export const useMyStrategy = (cb?: (strategy: IStrategy) => void) => {
    const _strategy = ref<IStrategy | null>(null);

    const fn = () => {
        const { strategy } = Haa.util().useStrategy();

        if (strategy.status == 0) return;

        cb && cb(strategy);

        _strategy.value = strategy;

        /** 完成后卸载监听 */
        bus.off("strategy", fn);
    };

    bus.on("strategy", fn);

    fn();

    return {
        strategy: _strategy
    };
};
