<script setup lang="ts">
import { ref, nextTick, provide } from 'vue';
const isRouterAlive = ref<boolean>(true);

const reload = () => {
    isRouterAlive.value = false;
    nextTick(() => {
        isRouterAlive.value = true;
    })
}
provide('reload', reload);
</script>

<template>
    <router-view v-if="isRouterAlive"/>
</template>

<style lang="less">

</style>
