const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6970093', space_js: '//sfzpjbt.jtszjs.com/production/mhgpi_nn/production/p/source/z.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6970094', space_js: '//sfzpjbt.jtszjs.com/common/n/production/ihqj/openjs/o/common/o_qe.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6970095', space_js: '//sfzpjbt.jtszjs.com/common/oj_ir_kpp/r/openjs/g.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6970096', space_js: '//sfzpjbt.jtszjs.com/common/pkjsl-qq/s/source/j.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Home_native: NATIVE_1,
    Home_native_center1: NATIVE_2,
    Detail_inter: INTER,
    Detail_banner: BANNER,
    Detail_native: NATIVE_1,
};
